import React from "react"

import Layout from "layout"
import HomeBanner from "components/StaticPages/Home/HomeBanner"
import HomeCard from "components/StaticPages/Home/HomeCard"
import HomeHowProgramWorks from "components/MentalHealth/Home/HomeHowProgramWorks"

const IndexPage = () => {
  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Concentrix"
    >
      <HomeBanner />
      <HomeCard />
      <HomeHowProgramWorks />
    </Layout>
  )
}

export default IndexPage
