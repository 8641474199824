import React, { useEffect } from "react"
import classNames from "classnames"

import Container from "layout/Container"
import Hero from "layout/Hero"
import HomeCardService from "./HomeCardService"

import styles from "../../utils/staticPages.module.scss"
import { animate } from "services/animations"

const HomeCard = () => {
  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-corporate",
      animateOn: ".animate-corporate-trigger",
    })
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-careers",
      animateOn: ".animate-careers-trigger",
    })
  }, [])

  return (
    <Hero>
      <Container isCentered desktop={10} fullhd={8}>
        <div className={classNames(styles["card"])} id="services">
          <div className="card-content">
            <div className="columns">
              <HomeCardService
                program={{
                  name: "Mental Health",
                  description: "Mind",
                  link: "/mind",
                }}
                link={{
                  url: "/mind",
                  label: "Book Teleconsult",
                }}
                color="secondary"
              >
                <p>Book a one-on-one with a mental health professional</p>
              </HomeCardService>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeCard
