import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "../utils/staticPages.module.scss"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medgrocer: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src
  const medgrocer = data.medgrocer.childImageSharp.fluid

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={10} fullhd={8}>
        {/* <figure className={styles["logo"]}>
          <Img fluid={medgrocer} alt="MedGrocer" />
        </figure>
        <h1 className="title is-size-1-desktop is-size-3-mobile has-text-primary">
          We are working to help your physical and mental wellbeing.
        </h1>
        <h3 className="subtitle is-size-3-desktop is-size-5-mobile">
          We look forward to serving you in September 2021.
        </h3> */}
        <figure className={styles["logo"]}>
          <Img fluid={medgrocer} alt="MedGrocer" />
        </figure>
        <h1 className="title is-size-1-desktop is-size-3-mobile has-text-primary">
          We are working to help your mental wellbeing.
        </h1>
        <h3 className="subtitle is-size-3-desktop is-size-5-mobile">
          We look forward to serving you in September 2021.
        </h3>

        <Button
          color="primary"
          size="medium"
          onClick={() => navigate("/#services")}
          className={classNames(styles["home__buttonIsFullwidth"])}
        >
          Learn more
        </Button>
      </Container>
    </Hero>
  )
}

export default HomeBanner
