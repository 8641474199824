import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "components/StaticPages/utils/staticPages.module.scss"

const HomeCardService = ({ program, children, link, color }) => {
  return (
    <div className="column px-2 mb-3">
      <span className="has-text-dark is-uppercase fade-to-upper-corporate">
        {program.description}
      </span>
      <Link to={program.link}>
        <h1
          className={`title mt-0 has-text-${
            color || "primary"
          } fade-to-upper-corporate`}
        >
          {program.name}
        </h1>
      </Link>
      <hr
        className={classNames(
          "fade-to-upper-corporate",
          styles["card__divider"]
        )}
      />
      {children}
      <p className="mt-1 fade-to-upper-corporate">
        <Link
          className={`button has-text-weight-bold is-${
            color || "primary"
          } fade-to-upper-corporate`}
          to={link.url}
        >
          {link.label}
        </Link>
      </p>
      <section className="animate-corporate-trigger"></section>
    </div>
  )
}

export default HomeCardService
